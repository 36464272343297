import { local_storage_keys } from "../../../utils/constants/storage";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utils/funcs/storage";

export function getDeviceUuid() {
  const deviceUuid = getLocalStorageItem(local_storage_keys.device_uuid);
  if (!deviceUuid) {
    const newDeviceUuid = crypto.randomUUID();
    setLocalStorageItem(local_storage_keys.device_uuid, newDeviceUuid);
    return newDeviceUuid;
  }
  return deviceUuid;
}
