export const exly_connect_apis = {
  inform_meeting_joined: "/exlycon/update/meeting",
  fetch_meeting_details_for_host: "/exlycon/start/host/meeting",
  fetch_meeting_details_for_customer: "/exlycon/join/customer/meeting",
};

export const auth_apis = {
  fetch_auth_token: "/users/auth/token",
};

export const api_status = {
  NOT_ACCEPTABLE: 406,
};
