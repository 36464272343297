import { getJwtTokenFromLocalStorage } from "../../../../utils/apis/auth";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import {
  exly_source_header,
  scoot_origin_header,
} from "../Apis/Apis.constants";
import { apiCall } from "../Apis/Apis.utils";
import { AUTH_TOKEN } from "../Auth/Auth.constants";
import {
  debugging__allowed_flows,
  debug_log_name,
  post_debug_logs_api,
} from "./DebugLogs.constants";

export const debugLogs = async ({ data, metadata = {} }) => {
  try {
    if (!debugging__allowed_flows.includes((metadata.flow || "").toLowerCase()))
      return;

    const logData = JSON.stringify({
      data,
      metadata: {
        ...metadata,
        time: Date.now(),
        aJwt: getJwtTokenFromLocalStorage(),
        log_name: debug_log_name,
        device_uuid: getDeviceUuid(),
        current_url: window.location.href,
      },
    });

    console.log(logData);

    await apiCall({
      url: post_debug_logs_api,
      body: { log_name: debug_log_name, log: logData },
      headers: {
        "Content-Type": "application/json",
        "auth-token": AUTH_TOKEN,
        ...exly_source_header,
        ...scoot_origin_header,
      },
      method: "POST",
    });
  } catch {
    // do nothing
    // dont care if logging fails
  }
};
