import { user_backend_host } from "../../../../utils/constants/hostsUrl";

export const debugging__all_flows = { host_referer: "host_referer" };

export const debugging__allowed_flows = (
  process.env.REACT_APP_CUSTOM_LOG_FLOWS || ""
)
  .split(",")
  .map((i) => i.trim().toLowerCase());

export const post_debug_logs_api = `${user_backend_host}/analytics/log/data`;

export const debug_log_name = "exly_connect_fed";
