export const meeting_attendee_roles = {
  host: 1,
  customer: 0,
};

export const dynamic_route_var_names = {
  meetingUuid: "meetingUuid",
};

// User details params, responsible to start the meeting
export const user_params = {
  user_name: "userName",
  user_id: "userId",
  user_email: "userEmail",
  from: "from"
};

export const default_from_url = "https://exlyapp.com/";
