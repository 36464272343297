/**
 * This utility returns the host leave url, note that param - ec=e1 is used to show a
 * toast message on CT screen
 * @returns the leave url for host
 */
export const getHostLeaveUrl = () => {
  const message = encodeURIComponent(
    "Note: You'll need to launch meeting again to join!"
  );
  return `${process.env.REACT_APP_CT_URL}/#/exly-connect/scheduled?em=${message}`;
};

/**
 * This utility returns the host leave url, incase the start meeting api is failing because
 * there are parallel meetings being launched by the user
 * @returns the leave url for the host
 */
export const getHostLeaveUrlBecauseParallelMeetings = ({ message }) => {
  const urlMessage = encodeURIComponent(message);
  // Here, em refers to error message, we do not want to write unnecessary url params in the url 
  return `${process.env.REACT_APP_CT_URL}/#/exly-connect/scheduled?em=${urlMessage}`;
};
