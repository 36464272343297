import { auth_apis } from "../constants/api";
import { user_backend_host } from "../constants/hostsUrl";
import { isMeetingHost } from "../funcs/data";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../funcs/storage";
import { local_storage_keys } from "../constants/storage";
import { removeJwtToken } from "../funcs/auth";
import { apiCall } from "../../features/Common/modules/Apis/Apis.utils";
import { AUTH_TOKEN } from "../../features/Common/modules/Auth/Auth.constants";
import {
  exly_source_header,
  scoot_origin_header,
} from "../../features/Common/modules/Apis/Apis.constants";

/**
 * There might be chances that the value returned is null.
 * Only use this where we are fine with this condition, for e.g, logging.
 * Ideally should be called after `getJwtToken()` is called
 */
export const getJwtTokenFromLocalStorage = () =>
  getLocalStorageItem(local_storage_keys.jwt_token);

export const getJwtToken = async ({
  userId,
  subDomain,
  meetingAttendeeRole,
}) => {
  let jwtToken = getJwtTokenFromLocalStorage();
  let storedUserId = getLocalStorageItem(local_storage_keys.user_id);
  if (userId !== storedUserId || !jwtToken) {
    removeJwtToken();
    removeLocalStorageItem(local_storage_keys.user_id);
    try {
      const userAuthTokenApiResponse = await apiCall({
        url: user_backend_host + auth_apis.fetch_auth_token,
        method: "POST",
        headers: {
          "auth-token": AUTH_TOKEN,
          ...exly_source_header,
          ...scoot_origin_header,
        },
        body: {
          client_id: userId,
          grant_type: "authorization_code",
          ...(isMeetingHost(meetingAttendeeRole)
            ? {
                refresh_token: "",
                staff_email: null,
              }
            : {
                token_type: 3,
                sub_domain: subDomain,
              }),
        },
      });

      jwtToken = userAuthTokenApiResponse?.data?.data?.tokens?.access_token;
      if (jwtToken) {
        setLocalStorageItem(local_storage_keys.jwt_token, jwtToken);
        setLocalStorageItem(local_storage_keys.user_id, userId);
      }
    } catch {}
  }
  return jwtToken;
};
