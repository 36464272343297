import { isMeetingHost } from "../funcs/data";
import { exly_connect_apis } from "../constants/api";
import { backend_host } from "../constants/hostsUrl";
import { getJwtToken } from "./auth";
import { apiCall } from "../../features/Common/modules/Apis/Apis.utils";
import {
  exly_source_header,
  scoot_origin_header,
} from "../../features/Common/modules/Apis/Apis.constants";

export const informMeetingJoined = async ({
  meetingAttendeeRole,
  userId,
  subDomain,
  meetingNumber,
}) => {
  if (isMeetingHost(meetingAttendeeRole)) {
    try {
      const jwtToken = await getJwtToken({
        userId,
        subDomain,
        meetingAttendeeRole,
      });

      apiCall({
        url: backend_host + exly_connect_apis.inform_meeting_joined,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "jwt-token": jwtToken,
          ...exly_source_header,
          ...scoot_origin_header,
        },
        body: { meetingNumber },
      });
    } catch {}
  }
};

export const fetchMeetingDetails = async ({
  meetingAttendeeRole,
  jwtToken,
  meetingUuid,
  userEmail,
  shareStartUrl = false,
}) => {
  const startMeetingApiResponse = await apiCall({
    url:
      backend_host +
      (isMeetingHost(meetingAttendeeRole)
        ? exly_connect_apis.fetch_meeting_details_for_host
        : exly_connect_apis.fetch_meeting_details_for_customer),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "jwt-token": jwtToken,
      ...exly_source_header,
      ...scoot_origin_header,
    },
    body: {
      meeting_uuid: meetingUuid,
      ...(isMeetingHost(meetingAttendeeRole) ? { user_email: userEmail } : {}),
      // shareStartUrl - This is given in the fetch meeting details api, so if true then the api will provide the
      // actual zoom server - app.zoom.us meeting url and we redirect the user to that
      ...(shareStartUrl ? { shareStartUrl: true } : {}),
    },
  });

  return startMeetingApiResponse;
};
