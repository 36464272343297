import React, { useEffect, useRef } from "react";
import { ZoomMtg } from "@zoom/meetingsdk";
import { useParams } from "react-router-dom";
import { auth_codes } from "../../utils/constants/auth";
import {
  dynamic_route_var_names,
  meeting_attendee_roles,
} from "../../utils/constants/data";
import {
  fetchMeetingDetails,
  informMeetingJoined,
} from "../../utils/apis/meeting";
import { MeetingNotStarted } from "./components/MeetingNotStarted";
import { BodyWrapper } from "./components/BodyWrapper";
import {
  getSessionStorageItem,
  removeSessionStorageItem,
} from "../../utils/funcs/storage";
import { stages } from "./data";
import { session_storage_keys } from "../../utils/constants/storage";
import { getJwtToken } from "../../utils/apis/auth";
import { removeJwtToken } from "../../utils/funcs/auth";
import { autoJoinAudio, autoJoinMeetOnHostJoin, setStageISS } from "./helpers";
import { checkIfMeetingUrlIsValid } from "../../utils/funcs/meetingAuthUrl";
import { logError } from "../../utils/funcs/logError";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("en-US");
export const Home = ({ meetingAttendeeRole }) => {
  const [stage, setStage] = React.useState(stages.mount);

  useEffect(() => {
    const isMeetingUrlValid = checkIfMeetingUrlIsValid({
      meetingAttendeeRole,
    });

    if (isMeetingUrlValid) {
      prepMeeting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingAttendeeRole]);

  useEffect(() => {
    removeSessionStorageItem(session_storage_keys.waiting_primary_btn_text);
    return () => {
      removeSessionStorageItem(session_storage_keys.stage);
      removeSessionStorageItem(session_storage_keys.waiting_primary_btn_text);
    };
  }, []);

  const dynamicRouteParams = useParams();
  const meetingUuid = dynamicRouteParams[dynamic_route_var_names.meetingUuid];

  const { leaveUrl } =
    getSessionStorageItem(session_storage_keys.user_details) || {};

  const joinMeeting = ({
    signature,
    meetingNumber,
    passWord,
    zakToken,
    userEmail,
    userName,
    userId,
    subDomain,
  }) => {
    autoJoinMeetOnHostJoin({ meetingAttendeeRole });
    // Todo - @shivansh - Remove this console

    ZoomMtg.join({
      signature,
      meetingNumber,
      userName,
      sdkKey: process.env.REACT_APP_ZOOM_CLIENT_ID,
      userEmail,
      passWord,
      ...(zakToken && {
        zak: zakToken,
      }),
      success: (success) => {
        setStageISS(stages.meeting_joined);
        informMeetingJoined({
          meetingAttendeeRole,
          userId,
          subDomain,
          meetingNumber,
        });

        // TODO - @shivansh - Need some check from backend to see if we need to
        // record this or not, in the api - /exlycon/start/host/meeting
        if (meetingAttendeeRole === meeting_attendee_roles.host) {
          ZoomMtg.record({
            record: true,
            success: () => {
              console.log("recording now");
            },
            error: (err) => {
              console.log("recording error: ", err);
            },
          });
        }

        autoJoinAudio();
      },
      error: (error) => {
        logError({
          error,
          when: "ZoomMtg.join",
          occuredAt: "src/pages/Home/index.js",
        });
      },
    });
  };

  const initMeeting = ({
    signature,
    meetingNumber,
    passWord,
    zakToken,
    userEmail,
    userName,
    userId,
    leaveUrl,
    subDomain,
  }) => {
    // hide zoom ui using element id `zmmtg-root`
    // and unhide by using following code
    // document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl,
      // Set to true to automatically apply the latest media dependency fix for the current Web Meeting SDK version
      // Note that you will still need to manually upgrade to major and minor version releases
      patchJsMedia: true,
      leaveOnPageUnload: true,
      // enable webinar attendee receive 1080P video when zoom backend support
      enableFullHD: true,
      success: (success) => {
        setStageISS(stages.meeting_initiated);
        joinMeeting({
          signature,
          meetingNumber,
          passWord,
          zakToken,
          userEmail,
          userName,
          userId,
          subDomain,
        });
      },
      error: (error) => {
        logError({
          error,
          when: "ZoomMtg.init",
          occuredAt: "src/pages/Home/index.js",
        });
      },
    });
  };

  const numFoRetries = useRef(0);

  async function prepMeeting() {
    const { userEmail, userName, userId, leaveUrl, subDomain } =
      getSessionStorageItem(session_storage_keys.user_details) || {};

    try {
      const jwtToken = await getJwtToken({
        userId,
        subDomain,
        meetingAttendeeRole,
      });

      const fetchMeetingDetailsApiResponse = await fetchMeetingDetails({
        meetingAttendeeRole,
        jwtToken,
        meetingUuid,
        userEmail,
      });
      const fetchMeetingDetailsApiData = fetchMeetingDetailsApiResponse?.data;

      if (
        [
          auth_codes.unknown_user,
          auth_codes.refresh_token_expired,
          auth_codes.access_token_missing,
          auth_codes.invalid_jwt_token,
          auth_codes.access_token_expired,
        ]
          .map((i) => i.status_code)
          .includes(fetchMeetingDetailsApiData?.status)
      ) {
        if (numFoRetries.current < 5) {
          // we need to refetch token and start again
          numFoRetries.current += 1;
          removeJwtToken();
          prepMeeting();
          return;
        } else {
          window.location.href = leaveUrl;
          return;
        }
      }

      if (
        fetchMeetingDetailsApiResponse?.status === 400 &&
        fetchMeetingDetailsApiData?.status === "BAD_REQUEST" &&
        fetchMeetingDetailsApiData?.message === "Meeting not started yet" // should be replaced with proper error code
      ) {
        setStage(stages.fetch_meeting_details_api_failed);
        return;
      }

      const joinMeetingParams = {
        meetingNumber: fetchMeetingDetailsApiData?.meetingNumber,
        signature: fetchMeetingDetailsApiData?.signature,
        passWord: window.atob(fetchMeetingDetailsApiData?.meetingPassword),
        zakToken: fetchMeetingDetailsApiData?.zakToken,
        userEmail,
        userName,
        userId,
        leaveUrl,
        subDomain,
      };
      if (
        joinMeetingParams?.meetingNumber &&
        joinMeetingParams?.signature &&
        joinMeetingParams?.passWord
      ) {
        initMeeting(joinMeetingParams);
      }
    } catch (err) {
      logError({
        error: err,
        when: "prepMeeting function call",
        occuredAt: "src/pages/Home/index.js",
      });
    }
  }

  // TODO: delete UserNameForm and below code after 19 Feb 2023
  // if (!userName) {
  //   return <BodyWrapper children={<UserNameForm />} />;
  // }

  if (stage === stages.fetch_meeting_details_api_failed) {
    return <BodyWrapper children={<MeetingNotStarted leaveUrl={leaveUrl} />} />;
  }

  return <></>;
};
